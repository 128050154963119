import React,{ useState } from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import SearchIcon from 'images/logo_o.svg'

export default connectSearchBox(
    ({ refine, currentRefinement, className, onFocus }) => {
  
      let timerId = null;
      const delay = 1000;
  
      const [newRefinement, setNewRefinement] = useState(currentRefinement);
      const [searchFocused, setSearchFocused] = useState(false);
  
      const onChangeDebounced = (event) => {
        const value = event.currentTarget.value;
  
        clearTimeout(timerId);
        timerId = setTimeout(() => refine(value), delay);
  
        setNewRefinement(value);
      };


    return (
      <form className={className} onSubmit={e => { e.preventDefault(); }}>
        <div className="absolute inset-y-0 left-0 px-6 flex items-center pointer-events-none">
          <SearchIcon className={`w-[15px] duration-300 ${searchFocused ? 'text-red' : 'text-grey'}`}/>
        </div>
        <input
          className={`block w-full shadow-input mx-auto font-body !leading-none bg-verylightgrey sm:py-7 py-6 pr-8 pl-12 text-grey focus:text-black border-0 focus:outline-none text-sm xxs:text-base lg:!text-lg tracking-[-0.5px] placeholder-grey ring-0 focus:ring-0`}
          type="text"
          placeholder="What do you want to search for?"
          aria-label="Search"
          onChange={(e) => onChangeDebounced(e)}
          value={newRefinement}
          onFocus={() => setSearchFocused(true)}
          onBlur={() => setSearchFocused(false)}
        />
      </form>
    )
  }
)