import { createRef, default as React, useState, useMemo, useEffect, useRef } from "react"
import algoliasearch from "algoliasearch"
import { InstantSearch } from "react-instantsearch-dom"
import SearchBox from "./SearchBox"
import SearchResult from "./SearchResult"
import CurrentFilters from "./CurrentFilters"
import ContentTypeFilter from "./ContentTypeFilter"
import Pagination from "./Pagination"
import persistantOrder from "./persistantOrder"
import useClickOutside from "./useClickOutside"
import Heading from "utils/Heading"
import MobileFilters from './MobileFilters'
import qs from 'qs';
import { navigate } from "gatsby"
import {BrowserView, MobileView} from 'react-device-detect'

const Search = ({ indices, location }) => {

    const createURL = state => `/roq-talk/?${qs.stringify(state)}`

    const searchStateToUrl = searchState =>
      searchState ? createURL(searchState) : '';

    const urlToSearchState = ({ search }) => { 
      return qs.parse(search.slice(1))
    };
    
    const rootRef = createRef()
    const [hasFocus, setFocus] = useState(false)
    const DEBOUNCE_TIME = 400
    const searchClient = useMemo(
      () =>
        algoliasearch(
          process.env.GATSBY_ALGOLIA_APP_ID,
          process.env.GATSBY_ALGOLIA_SEARCH_KEY
        ),
      []
    )
    useClickOutside(rootRef, () => setFocus(false))

    const [searchState, setSearchState] = useState(urlToSearchState(location));
    const debouncedSetStateRef = useRef(null);
  
    function onSearchStateChange(updatedSearchState) {

      clearTimeout(debouncedSetStateRef.current);

      debouncedSetStateRef.current = setTimeout(() => {
        navigate(searchStateToUrl(updatedSearchState));
      }, DEBOUNCE_TIME);
  
      setSearchState(updatedSearchState);
    }
  
    useEffect(() => {
      setSearchState(urlToSearchState(location));
    }, [location]);    

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={indices[0].name}
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
            createURL={createURL}            
        >
            <div className="lg:h-[400px] md:h-[300px] h-[250px] w-full bg-black relative flex justify-center items-center">
              <h1 className="text-white px-6 font-display md:text-8xl text-7xl uppercase">Roq Talk</h1>
            </div>
            <div className="max-w-7xl mx-auto pb-16">
                <div className="w-full lg:-mt-10 -mt-10 relative z-100">
                  <div className="lg:max-w-6xl flex-grow mx-auto w-11/12">
                    <SearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} className="relative"/>
                    { <MobileView>
                        <MobileFilters>
                          <CurrentFilters />
                          <ContentTypeFilter 
                            attribute="field_component" 
                            transformItems={items => persistantOrder(items)}
                          />
                        </MobileFilters>
                      </MobileView>
                    }
                  </div>
                </div>              
                <div className="flex flex-row py-16 px-6">
                  <div className="w-1/5 pr-6 hidden lg:block">
                    <Heading size="h4" className="text-red font-display">Filters</Heading>
                    {
                      <BrowserView>
                        <CurrentFilters />
                        <ContentTypeFilter 
                          attribute="field_component" 
                          transformItems={items => persistantOrder(items)}
                        />
                      </BrowserView>
                    }
                  </div>
                  <div className="lg:w-4/5 w-full">
                    <SearchResult 
                      show={searchState && searchState.length > 0 && hasFocus} 
                      indices={indices}
                    />
                  </div>
                </div>
                <div>
                  <Pagination />
                </div>
            </div>
        </InstantSearch>            
    )
}

export default Search