import { Link } from "gatsby"
import resolveLink from "utils/resolveLink"
import React, { useEffect, useState } from "react"
import { connectHits, Index } from "react-instantsearch-dom"
import Heading from "utils/Heading"
import Label from "utils/Label"
import LogoO from "images/logo_o.svg"

const PageHit = ({ hit }) => {
  return (
    <Link className="group relative" to={resolveLink(`/${hit.full_slug}`)}>
        <div className="relative w-full max-h-[230px] bg-gray-200 overflow-hidden duration-300">
          {hit.thumbnail_image?.filename &&
            <img className="w-full h-full object-center lg:w-full lg:h-full" src={hit.thumbnail_image?.filename} alt={hit.thumbnail_image?.alt}/>
          }
          <div className="group-hover:opacity-100 opacity-0 duration-300 flex flex-col justify-center items-center absolute top-0 left-0 h-full w-full">
            <div className="opacity-80 bg-red absolute top-0 left-0 h-full w-full"></div>
            <LogoO className="text-white w-[58px] z-10"/>
          </div>
        </div>
        <div className="mt-4 flex justify-between flex-col">
            <Label className="text-red mb-[3px]">{hit.component?.replace(/-/g, ' ').replace(/_/g, ' ')}</Label>
            <h3 className="text-black text-base leading-tight tracking-[-0.75px] font-medium font-body duration-300">
                {hit.name}
            </h3>
        </div>
    </Link>    
  )
}

const Hits = ({ hits }) => {
  const [noResults, setNoResults] = useState()
  // Check if there are any search results
  useEffect(() =>{
    if( hits.length < 1 ) {
      setNoResults(true)
    } else {
      setNoResults(false)
    }
  }, [hits])

  return (
    <>
    <div className="max-w-7xl mx-auto mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
      {hits.map(hit => (
        <PageHit hit={hit} />
      ))}
    </div>
    {noResults &&
      <div className="max-w-2xl mx-auto flex flex-col justify-center items-center text-center gap-4 py-24">
        <LogoO className="text-red w-[30px]"/>
        <Heading size="h4" className="text-black">We didn't find any match for your search.</Heading>
      </div>
    }
    </>
  )
}

const CustomHits = connectHits(Hits);

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <CustomHits />
  </Index>
)

const SearchResult = ({ indices, show }) => (
  <div>
    <Heading size="h4" className="text-red font-display mb-10">Insights &amp; resources</Heading>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
)

export default SearchResult
