import React from "react";
import Search from "components/ROQ/Search";
import Layout from "components/Layout";
import { PageContext } from "utils/context";
import Seo from "src/utils/seo"

const SearchPage = ({ location }) => {
  const searchIndices = [{ name: `Pages`, title: `Pages` }];
  return (
    <PageContext.Provider
      value={{
        breadcrumb: '',
        location: location,
      }}
    >      
      <Seo pageName={'Roq Talk'}/>
      <Layout location={location}>
          <Search indices={searchIndices} location={location}/>
      </Layout>
    </PageContext.Provider>
  );
};

export default SearchPage;