import React, { useState, useEffect } from "react"
import Chevron from 'images/header_chevron.svg'
import ClearFilters from "./ClearFilters"

const MobileFilters = ({ children }) => {

  const [open, setOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false);

  // Change the style of the navbar on scroll
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  // Set the body to overflow hidden when mobile filter is open
  useEffect(() =>{
    if( open ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible"
    }
    window.addEventListener("scroll", handleScroll);
  }, [open, scrolled])

  return (
        <>
          {/* Filter Button */}
          <div 
            className={'bg-red block lg:hidden'}
            onClick={() => setOpen(true)}
            onKeyPress={() => setOpen(true)}
            role="button"
            tabIndex="0"
          >
            <div className="h-px w-11/12 mx-auto bg-verylightgrey opacity-50"></div>
            <div className="font-body text-base tracking-[-0.5px] text-white !leading-none text-center w-full py-5 font-medium">Filters +</div>
          </div>
        {/* Filter Popup */}
        <div className={`${open ? 'visible' : 'invisible'}`}>
            <div className={`z-[9999] fixed inset-0 overflow-y-auto top-0 ${open ? 'opacity-100' : 'opacity-0'} duration-300 ease-in-out transition`}>
                <div className={`flex items-end justify-center text-center sm:block sm:p-0 h-screen`}>
                    <div className="flex flex-col justify-between min-h-full w-full align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all">
                      <div>
                        <div className="bg-white py-4 shadow px-6 flex flex-row justify-between items-end h-[98px]">
                            <button className="text-darkgrey font-body text-base font-medium tracking-[-0.5px] flex flex-row items-center w-full focus:outline-none" onClick={() => setOpen(false)}>
                                <Chevron className="text-black w-[13px] rotate-90 mr-2"/>
                                <span>Filters</span>
                            </button>
                            <button
                                type="button"
                                className="bg-white rounded text-darkgrey focus:outline-none"
                                onClick={() => setOpen(false)}
                            >
                                <span className="sr-only">Close</span>
                                <button className={`relative h-[25px] w-[25px] outline-none focus:outline-none mb-1`}>
                                  <div className={`bg-black -translate-y-1/2 translate-z-0 absolute left-0 h-0.5 transform-gpu transition-all duration-300 top-1/2 w-full rotate-[135deg]`}></div>
                                  <div className={`bg-black translate-y-1/2 translate-z-0 absolute left-0 h-0.5 transform-gpu transition-all duration-300 bottom-1/2 w-full rotate-[-135deg]`}></div>
                                </button>
                            </button>
                        </div>
                        <div className="w-full px-6 pt-6">
                            <div className="flex flex-col gap-1">
                              {children}
                            </div>
                        </div>
                      </div>
                      <div className="px-6 py-4 flex flex-row gap-2">
                        <ClearFilters className="w-1/2"/>
                        <button
                            type="button"
                            className="w-1/2 cursor-pointer inline-flex justify-center items-center px-5 py-3 line-crop font-body font-medium tracking-[-0.5px] text-white transition duration-300 text-sm !leading-none bg-red mx-auto text-center focus:outline-none"
                            onClick={() => setOpen(false)}
                        >
                            See results
                        </button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default MobileFilters