import React from "react"
import { connectRefinementList } from 'react-instantsearch-dom';
import TitleCase from "utils/TitleCase";
import Label from "utils/Label";

const RefinementList = ({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL,
}) => (
  <ul className="py-5">
    <Label className="text-darkgrey mb-4">Filter by content type</Label>
    {items.map(item => (
      <li key={item.label}>
        <a
          href={createURL(item.value)}
          onClick={event => {
            event.preventDefault();
            refine(item.value);
          }}
        >
            <div className="flex flex-row items-center group my-1">
                <div className="relative h-4 w-4 flex justify-center items-center">
                  <div className={`absolute top-0 left-0 h-4 w-4 bg-white text-white focus:ring-0 ring-0 focus:outline-none outline-none pointer-events-none border group-hover:!border-red transition-colors duration-300 rounded-full ${item.isRefined ? '!border-red border-[5px]' : '!border-grey'}`}></div>
                </div>
                <label for="color-0" className={`ml-2 font-body text-base !leading-snug tracking-[-0.5px] cursor-pointer mt-0.5 transition-colors duration-300 ${item.isRefined ? "font-medium" : "font-light"}`}>
                    {TitleCase(item.label.replace(/-/g, ' ').replace(/_/g, ' '))}
                </label>
            </div>          
        </a>
      </li>
    ))}
  </ul>
);

export default connectRefinementList(RefinementList);
