import React from "react";
import ClearFilters from "./ClearFilters"
import { connectCurrentRefinements } from "react-instantsearch-dom";
import TitleCase from "utils/TitleCase";
import Label from "utils/Label"

const CurrentRefinements = ({ items, refine, createURL }) => {
  return (
    items.length > 0 &&
        <div>
            <ClearFilters className="my-6 lg:block hidden"/>
            <Label className="text-darkgrey my-4">Selected</Label>
            <ul className="flex lg:flex-col flex-row flex-wrap lg:border-b border-grey lg:pb-6 gap-1.5">
            {items.map((item) => (
                item.items ? (
                    <React.Fragment>
                        {item.items.map((nested) => (
                            <li className="bg-red text-white font-medium py-3 px-4 font-body text-base !leading-snug self-start tracking-[-0.5px]" key={nested.label}>
                                <a
                                    href={createURL(nested.value)}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        refine(nested.value);
                                    }}
                                >
                                    {TitleCase(nested.label.replace(/-/g, ' ').replace(/_/g, ' '))}
                                    <i class="fa fa-times ml-3" aria-hidden="true"></i>
                                </a>
                            </li>
                        ))}
                    </React.Fragment>
                ) : (
                    <a
                        href={createURL(item.value)}
                        onClick={(event) => {
                            event.preventDefault();
                            refine(item.value);
                        }}
                    >
                        {item.label.replace(/-/g, ' ').replace(/_/g, ' ')}
                    </a>
                )
            ))}
            </ul>
        </div>
  )
}

export default connectCurrentRefinements(CurrentRefinements);
